@import "../../styles/theme.scss";

.ManualUploadModal {
  position: relative;
  overflow: hidden;
  max-height: 100vh;

  &__content {
    display: flex;
    width: calc(100% - 48px);
    margin: 24px;
    background: #fff;
    border-radius: 8px;
    z-index: 1001;
    max-height: calc(100vh - 48px);
    overflow: auto;

    @media (max-width: $medium) {
      width: 100%;
      margin: 0;
    }
  }

  &__arrowLeftIcon {
    margin-right: 18px;
    color: $tiicker-green;
  }

  &__container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 24px;

    @media (max-width: $medium) {
      flex-direction: column;
      padding: 0;
    }
  }

  &__column {
    flex: 1;
    position: relative;
    min-width: 0;

    &:first-child {
      padding-right: 24px;

      @media (max-width: $medium) {
        padding-right: 0;
      }
    }

    &:last-child {
      flex: 1.1;
    }
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }

  &__title {
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 24px;
    margin-top: 48px;
    color: $navy;

    @media (max-width: $medium) {
      margin-top: 24px;
      margin-bottom: 12px;
    }
  }

  &__subtitle {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    margin-bottom: 16px;
    color: $grey;

    @media (max-width: $medium) {
      font-size: 14px;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    color: $navy;

    @media (max-width: $medium) {
      font-size: 14px;
    }
  }

  &__listItem {
    padding-left: 16px;
    margin-bottom: 4px;
    position: relative;

    &::before {
      content: "•";
      position: absolute;
      left: 0;
    }
  }

  &__text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: $grey;

    @media (max-width: $medium) {
      font-size: 14px;
    }
  }

  &__dropzone {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2385BB65FF' stroke-width='2' stroke-dasharray='10%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    padding: 42px;
    text-align: center;
    margin-bottom: 24px;
    transition: all 0.2s;
    margin-top: 108px;

    @media (max-width: $medium) {
      margin-top: 16px;
      margin-bottom: 0;
    }

    &--active {
      border-color: #3b82f6;
      background-color: #eff6ff;
    }

    &__icon {
      font-size: 48px;
      color: $grey;
      margin-bottom: 16px;
      opacity: 0.7;
    }

    &__textBlock {
      display: flex;
      justify-content: center;
      gap: 8px;
    }

    &__text {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
    }

    &__subtext {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: #6b7280;
    }

    &__sizeText {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: $grey;
      opacity: 0.7;
    }
  }

  &__fileLabel {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    display: inline-block;
    color: $tiicker-green;
    cursor: pointer;
    transition: all 0.2s;
    text-decoration: underline;

    &:hover {
      color: darken($tiicker-green, 10%); // Darken the color on hover
    }
  }

  &__fileInput {
    display: none;
  }

  &__fileList {
    max-height: 280px;
    overflow-y: auto;
    margin-bottom: 24px;

    @media (max-width: $medium) {
      margin-bottom: 10px;
    }
  }

  &__fileItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 4px;
    border-radius: 6px;
    margin-bottom: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  &__fileInfo {
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1;
    min-width: 0;
  }

  &__fileIcon {
    color: $tiicker-green;
  }

  &__fileName {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: $grey;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__fileRemove {
    background: none;
    border: none;
    color: $navy;
    cursor: pointer;
    transition: color 0.2s;
    font-size: 18px;

    &:hover {
      color: #ef4444;
    }
  }

  &__fileActions {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: 12px;
  }

  &__fileSize {
    font-size: 16px;
    color: $grey;
    opacity: 0.5;
  }

  &__errors {
    margin: 1rem 0;
    color: red;
  }

  &__error {
    margin: 0.5rem 0;
  }
}
