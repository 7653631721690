@import "../../../../styles/theme.scss";


.PerkMegaModal {
  &__container {
    margin-top: 24px;
    padding: 24px;
    background-color: $pale-mint;
    width: 100%;
    margin-bottom: 24px;

    &__headingContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 10px;

      &__infoCircleImg {
        // width: 20px;
        // height: 20px;
      }

      &__heading {
        font-size: 18px;
        margin: 0; // Remove default margin from h4

        @media (max-width: $medium) {
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
        }
      }
    }



    &__requirement {
      line-height: 1.4;
      font-size: 16px;
      margin-bottom: 8px;

      @media (max-width: $medium) {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
      }
    }

    &__disclaimer {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 1.4;

      @media (max-width: $medium) {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
      }
    }
  }
}

