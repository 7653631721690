@import "../../../../styles/theme.scss";


.UploadError {
   
  &__container {
    margin-top: 24px;
    padding: 24px;
    background-color: $error-background-color;
    width: 100%;
    margin-bottom: 24px;

    &__headingContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 10px;

      &__infoCircleContainer {
        position: relative;
        background-color: white;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        
        &__warningCircleImg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 16px;
          width: 16px;
        }
      }
      
  
      &__heading {
        font-size: 18px;
        margin: 0; // Remove default margin from h4
      }
    }
    
    
    &__requirement {
      line-height: 1.4;
      font-size: 16px;
      margin-bottom: 8px;
    }
    
    &__disclaimer {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 1.4;
    }  
  } 
}

