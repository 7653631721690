@import "../../../styles/theme";

.BrokerageConnectIssue {
  padding: 24px;
  background: $error-background-color;
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 24px;
  position: relative;

  @media (max-width: $medium) {
    display: none; // Hide the inline version on mobile
  }

  &__close {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }

  &__iconBackground {
    background: transparent;
    width: 42px;
    height: 32px;
    border-radius: 50%;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    //align-items: center;
  }

  &__icon {
    color: $disconnect-error;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: -0.16px;
    text-align: left;
    color: $navy;
  }

  &__message {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: $grey;
    opacity: 0.8;
    margin-bottom: 0;

    a {
      color: $navy;
    }
  }

  // Mobile Modal Styles
  &__modal {
    @media (max-width: $medium) {
      max-width: 90%;
      margin: 20px auto;
    }
  }

  &__mobileContent {
    padding: 24px 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__mobileIconContainer {
    margin-bottom: 20px;
  }

  &__mobileTitle {
    font-size: 18px;
    font-weight: 500;
    color: $navy;
    margin-bottom: 16px;
    line-height: 1.2;
    padding: 0 8px;
  }

  &__mobileMessage {
    font-size: 14px;
    line-height: 1.5;
    color: $grey;
    margin: 0;
    padding: 0 8px;

    a {
      color: $navy;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
