@import "../../../styles/theme";

.BrokerageConnectMessage {
  padding: 28px 24px 24px 68px;
  background: $pale-mint;
  width: 95%;

  @media (max-width: $medium) {
    padding: 20px 16px;
    width: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;

    @media (min-width: $medium) {
      cursor: default;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: -0.18px;
    text-align: left;
    color: $navy;
    margin: 0;
    padding-right: 32px;

    @media (max-width: $medium) {
      font-size: 16px;
    }
  }

  &__toggle {
    display: none;
    background: none;
    border: none;
    padding: 4px;
    color: $navy;
    cursor: pointer;

    @media (max-width: $medium) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:focus {
      outline: none;
    }
  }

  &__icon {
    display: block;
  }

  &__content {
    @media (max-width: $medium) {
      height: 0;
      overflow: hidden;
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }

    &_expanded {
      @media (max-width: $medium) {
        height: auto;
        opacity: 1;
        margin-top: 12px;
      }
    }
  }

  &__message {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: $grey;
    opacity: 0.8;
    margin: 12px 0 0 0;

    @media (max-width: $medium) {
      font-size: 13px;
      margin: 0;
    }

    a {
      color: $navy;
    }
  }
}
